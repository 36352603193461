import {
  RecommendedH2,
  RecommendedInnerHeadingMain,
} from "@/StyledComponents/LandingStyled";
import React from "react";
import Clutch49ReviewSvg from "../svg/Clutch49ReviewSvg";
import CurveClutchReviewSvg from "../svg/CurveClutchReviewSvg";
import ClutchNameSvg from "../svg/ClutchNameSvg";
import DesignRushSvgBadge from "../svg/DesignRushSvgBadge";
import Link from "next/link";

export default function ClutchReviewTitleSec() {
  return (
    <RecommendedInnerHeadingMain className="counter">
      <RecommendedH2 className="clutch-review-h2">
        <span className="relative">
          4.9{" "}
          <span className="absolute fullsizesvgHauto top-[-5px] left-[-25px] right-[-25px]">
            <Clutch49ReviewSvg />
          </span>
        </span>
        &nbsp;is our&nbsp;&nbsp;
        <CurveClutchReviewSvg />
        <br />
        <div className="flex items-start">
          <ClutchNameSvg />
          &nbsp;average
        </div>
      </RecommendedH2>
      <div className=" md:block">
        {/* <ClutchSvgIcon /> */}
        <Link
          href="https://www.designrush.com/agency/profile/it-services-india"
          target="_blank"
        >
          <DesignRushSvgBadge />
        </Link>
      </div>
    </RecommendedInnerHeadingMain>
  );
}
